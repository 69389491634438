import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@saatva-bits/pattern-library.components.icon'

import styles from './InfoTooltip.module.scss'

const InfoTooltip = ({
    title,
    children,
    className,
    buttonClassName,
    contentClassName
}) => {
    const ref = useRef(null)
    const [isVisible, setVisiblity] = useState(false)

    const toggleToolTip = () => {
        setVisiblity(!isVisible)
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setVisiblity(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => document.removeEventListener('click', handleClickOutside, true)
    }, [])

    const classes = classNames(styles.infoTooltip, className)
    const buttonClasses = classNames(styles.button, buttonClassName)
    const contentClasses = classNames(
        styles.content,
        { [styles['content--hidden']]: !isVisible },
        contentClassName
    )

    return (
        <div className={classes} ref={ref}>
            <button
                aria-label="Toggle Tool Tip"
                className={buttonClasses}
                onClick={toggleToolTip}
                type="button"
            >
                <Icon
                    alt="Display Tool Tip"
                    description="Display Tool Tip"
                    name="tooltip"
                    titleId='display-tool-tip'
                />
            </button>
            <div className={contentClasses}>
                {title && <span className={styles.title}>{title}</span>}
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

InfoTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    buttonClassName: PropTypes.string,
    contentClassName: PropTypes.string
}

export default InfoTooltip
