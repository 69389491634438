import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@saatva-bits/pattern-library.components.icon/dist'
import { useOnClickOutside } from '@saatva-bits/pattern-library.hooks.use-on-click-outside'

import styles from './DropdownPanel.module.scss'

/**
 * @param {object} props 
 * @param {string} props.title
 * @param {string?} props.dropdownPanelClassName
 * @param {string?} props.titleClassName
 * @param {boolean?} props.stopPropagation - should stop propagation event when clicking child components in panel?
 * @param {import('react').ReactNode} props.children
 */
const DropdownPanel = ({ title, dropdownPanelClassName, titleClassName, stopPropagation = true, children }) => {
    const containerRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside(containerRef, () => isOpen && setIsOpen(false))
    
    const dropdownPanelClassNames = classNames(styles.dropdownPanel,
        dropdownPanelClassName
    )

    return (
        <menu
            tabIndex={0}
            aria-haspopup={true}
            aria-expanded={isOpen}
            className={styles.dropdownContainer}
            onClick={()=>setIsOpen(!isOpen)}
            data-selector={`dropdown-title-${title}`}
            ref={containerRef}
        >
            <div className={classNames(styles.dropdownTitle, titleClassName)}>
                {title}
                <Icon
                    className={styles.chevronIcon}
                    name="expand"
                    alt="Expand icon"
                    description="Expand icon"
                    titleId="expandIcon"
                />
            </div>
            <div
                className={dropdownPanelClassNames}
                onClick={(e) => {
                    if (stopPropagation) {
                        e.stopPropagation()
                    }
                }}
            >
                {children}
            </div>
        </menu>
    )
}

DropdownPanel.propTypes = {
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.string
}

export default DropdownPanel