import React from 'react'
import PropTypes from 'prop-types'

import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'

import RightSetup from '@/components/RightSetup/RightSetup'
import ValuePropsSection from '@/components/ValuePropsSection/ValuePropsSection'
import ReviewSection from '@/components/ReviewSection/ReviewSection'
import SupportValueProps from '@/components/SupportValueProps'

const FurnitureContent = ({ category, reviews }) => {
    return (
        <>
            <ReviewSection reviews={reviews} />
            <RightSetup categoryName={category} />
            <ValuePropsSection configKey={category} background="contrast-1" />
            <SupportValueProps />
            <FaqSection />
        </>
    )
}

FurnitureContent.propTypes = {
    category: PropTypes.string.isRequired
}

export default FurnitureContent
