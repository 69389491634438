import { useEffect, useRef } from 'react'
import classNames from 'classnames'

import styles from './QuizMattress.module.scss'

const JEBBIT_QUIZ_EVENTS = {
    questionAnswered: 'questionAnswered',
    quizCompleted: 'quizCompleted',
    quizResultAction: 'quizResultAction'
}

const JEBBIT_QUIZ_ANSWERS_WITH_RESULTS = [
    'I love it. It’s just old and I need a new one'
]

const Quiz = () => {
    const iframeRef = useRef(null)

    useEffect(() => {
        const getIframeHeight = () => {
            const dataLayerEvent = window?.dataLayer?.length > 0 && window.dataLayer[window.dataLayer.length - 1]
            const quizEvent = dataLayerEvent?.iframe?.event
            const quizAnswer = dataLayerEvent?.iframe?.quizAnswer

            if (JEBBIT_QUIZ_ANSWERS_WITH_RESULTS.includes(quizAnswer)) {
                iframeRef.current.classList.add(styles.quizAnswerResults)
            }

            if (quizEvent === JEBBIT_QUIZ_EVENTS.quizCompleted) {
                iframeRef.current.classList.add(styles.quizCompleted)
            }

            if (quizEvent === JEBBIT_QUIZ_EVENTS.quizResultAction) {
                iframeRef.current.classList.remove(styles.quizAnswerResults)
                iframeRef.current.classList.remove(styles.quizCompleted)
                iframeRef.current.scrollIntoView({ behavior: 'smooth' })
            }
        }

        window.addEventListener('message', getIframeHeight)

        return () => {
            window.removeEventListener('message', getIframeHeight)
        }
    }, [])

    return (
        <section className={classNames('section', styles.section)}>
            <div className={classNames('container', styles.quizContainer)}>
                <iframe
                    title="jebbit iframe quiz"
                    data-selector="jebbit-iframe"
                    className={styles.quiz}
                    src="https://saatva.jebbit.com/5kmeejug?L=Full+Page&deferred=true"
                    seamless={true}
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen={true}
                    ref={iframeRef}
                />
            </div>
        </section>
    )
}

export default Quiz
