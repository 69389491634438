import React from 'react'

import {
    SvgGoodForTheWorld,
    SvgHypoallergenic,
    SvgHypoallergenicFeather,
    SvgFairTrade
} from './Svgs'

import styles from './ValuePropCards.module.scss'

const valuePropsConfig = [
    {
        title: 'Hypoallergenic for sensitive sleepers',
        description: 'Naturally hypoallergenic, moisture-wicking fabrics and other hypoallergenic materials help allergy sufferers enjoy more restful nights.',
        svg: <SvgHypoallergenicFeather className={styles.svg} />
    },
    {
        title: 'Breathable for those who sleep hot',
        description: 'We craft our bedding with breathable fabrics and other innovative materials so you stay cozy without overheating.',
        svg: <SvgHypoallergenic className={styles.svg} stroke="#B19780" />
    },
    {
        title: 'Better for you & the planet',
        description: `We pride ourselves on a supply chain that's traceable from end to end. We use fibers from crops grown without the use of GMOs or pesticides and other harmful chemicals.`,
        svg: <SvgGoodForTheWorld className={`${styles.svg} ${styles['svg-world']}`} stroke="#B19780" />
    },
    {
        title: 'Responsibly sourced & crafted',
        description: 'We use the finest quality natural fabrics, sourced and crafted with the highest standards of economic, social, and environmental responsibility.',
        svg: <SvgFairTrade className={styles.svg} />
    }
]

export default valuePropsConfig
