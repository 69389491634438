import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@saatva-bits/pattern-library.components.button'

import styles from './ExtendOnRamp.module.scss'

const ExtendOnRamp = ({
    sectionClassName,
    containerClassName,
    contentClassName,
    titleClassName,
    buttonClassName
}) => {
    const containerClassNames = classNames(
        'container',
        styles.container,
        containerClassName
    )

    const sectionClassNames = classNames(
        'section',
        styles.section,
        sectionClassName
    )
    const contentClassNames = classNames(
        styles.content,
        contentClassName
    )
    const titleClassNames = classNames(
        't-heading2 t-color--white',
        styles.title,
        titleClassName
    )
    const buttonClassNames = classNames(styles.button, buttonClassName)

    return (
        <section className={sectionClassNames}>
            <div className={containerClassNames}>
                <div className={contentClassNames}>
                    <div className="row row--center-xs">
                        <div>
                            <h2 className={titleClassNames}>
                                Explore Extend product protection
                            </h2>
                            <div>
                                <Button
                                    kind="primary"
                                    className={buttonClassNames}
                                    href="/extend"
                                >
                                    Shop now
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

ExtendOnRamp.propTypes = {
    sectionClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    buttonClassName: PropTypes.string
}

export default ExtendOnRamp
