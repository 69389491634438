import React from 'react'
import PropTypes from 'prop-types'

const SvgGoodForTheWorld = ({ className, stroke }) => {
    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" className={className}>
            <path d="m42.09,40c-2.87,4.79-8.1,8-14.09,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m42.59,24c1.18,2.26,1.85,4.83,1.85,7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m11.56,31.56c0-2.73.68-5.3,1.85-7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m13.41,24c2.74-5.27,8.24-8.88,14.59-8.88s11.85,3.61,14.59,8.88" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m28,48c-5.99,0-11.21-3.21-14.09-8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m42.09,40c1.48-2.47,2.35-5.35,2.35-8.44,0,0,0,0,0,0" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m11.56,31.56s0,0,0,0c0,3.09.87,5.97,2.35,8.44" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="28" y1="15.12" x2="28" y2="11.46" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="28" y1="11.46" x2="28" y2="7.98" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m28,11.46c0-1.38-.72-2.72-2-3.46-1.28-.74-2.8-.69-4,0,0,1.38.72,2.72,2,3.46,1.28.74,2.8.69,4,0" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m33.03,4.14c-1.34-.36-2.81-.01-3.86,1.03-1.05,1.05-1.39,2.52-1.03,3.86,1.34.36,2.81.01,3.86-1.03,1.05-1.05,1.39-2.52,1.03-3.86Z" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m21.08,24c-.65,2.24-1.03,4.81-1.07,7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m21.08,40c1.38,4.78,3.96,8,6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m20.01,31.56c0,.15-.01.29-.01.44,0,2.92.4,5.64,1.08,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,24c-1.38-4.78-3.96-8-6.92-8s-5.54,3.22-6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,40c-1.38,4.78-3.96,8-6.92,8" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m35.99,31.56c-.04-2.75-.42-5.32-1.07-7.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <path d="m34.92,40c.68-2.36,1.08-5.08,1.08-8,0-.15,0-.29-.01-.44" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="20.01" y1="31.56" x2="11.56" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="35.99" y1="31.56" x2="20.01" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="44.44" y1="31.56" x2="44.44" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="35.99" y1="31.56" x2="44.44" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="11.56" y1="31.56" x2="11.56" y2="31.56" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="40" x2="34.92" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="40" x2="13.91" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="13.91" y1="40" x2="13.89" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="42.09" y1="40" x2="42.11" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="40" x2="42.09" y2="40" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="24" x2="42.59" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="21.08" y1="24" x2="13.41" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="34.92" y1="24" x2="21.08" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="42.59" y1="24" x2="42.6" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
            <line x1="13.41" y1="24" x2="13.4" y2="24" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25"/>
        </svg>
    )
}

SvgGoodForTheWorld.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string
}

const SvgHypoallergenic = ({ className, stroke = '#D5AA63', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M32 48C34.21 48 36 46.21 36 44C36 41.79 34.21 40 32 40H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M32 8C34.21 8 36 9.79 36 12C36 14.21 34.21 16 32 16H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 16C46.21 16 48 17.79 48 20C48 22.21 46.21 24 44 24H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44 40C46.21 40 48 38.21 48 36C48 33.79 46.21 32 44 32H8" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgHypoallergenic.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

const SvgHypoallergenicFeather = ({ className, stroke = '#B19780', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M20.28 23.83C17.56 28.54 12 38 12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 31.83C37.53 30.45 38.89 29.09 40 27.83V23.83H43.72C45.89 20.08 47.32 15.85 47.81 11.34C48.02 9.43002 46.4 7.81002 44.49 8.02002C36.35 8.91002 29.13 12.84 24 18.66" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M31.07 35.83C25.12 40.19 17.97 43.83 12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L40 15.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 47.83L12 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L44 43.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 27.83L12 11.83" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgHypoallergenicFeather.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

const SvgFairTrade = ({ className, stroke = '#B19780', fill = 'none' }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill={fill} xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M28 43V29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 11C26 9.9 26.9 9 28 9C29.1 9 30 9.9 30 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30 11H41.64" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30 11C30 12.1 29.1 13 28 13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3601 11H44.0001" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.64 11H12" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3601 11H26.0001" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26 11C26 12.1 26.9 13 28 13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 29V13" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6399 11L41.9999 9L42.3599 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.3601 11L45.6401 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6401 11L38.3601 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.6399 11H42.3599" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.64 29H48C48 32.31 45.31 35 42 35C38.69 35 36 32.31 36 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M36 29H38.36" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.6401 29H38.3601" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3599 11L13.9999 9L13.6399 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3601 29L13.6401 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.6401 29L14.3601 11" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3599 11H13.6399" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.36 29H8C8 32.31 10.69 35 14 35C17.31 35 20 32.31 20 29" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.9999 29H17.6399" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.3601 29H17.6401" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M28 43H20V47H36V43H28Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

SvgFairTrade.propTypes = {
    className: PropTypes.string,
    stroke: PropTypes.string,
    fill: PropTypes.string
}

export {
    SvgGoodForTheWorld,
    SvgHypoallergenic,
    SvgHypoallergenicFeather,
    SvgFairTrade
}
