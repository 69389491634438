import CATEGORIES from '@/temp-configs/category-constants'
import MattressesContent from './Mattresses'
import FurnitureContent from './Furniture'
import BeddingContent from './Bedding'
import BundlesContent from './Bundles'

const categoryContentConfig = {
    [CATEGORIES.MATTRESSES]: {
        content: MattressesContent
    },
    [CATEGORIES.FURNITURE]: {
        content: FurnitureContent
    },
    [CATEGORIES.BEDDING]: {
        content: BeddingContent
    },
    [CATEGORIES.BUNDLES]: {
        content: BundlesContent
    }
}

export default categoryContentConfig
