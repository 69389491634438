import Switch from '@/components/Switch/Switch'

import { useFiltersState } from '@/contexts/filters'

import styles from './InStockSwitch.module.scss'

const InStockSwitch = ({ handleFilterChange }) => {
    const filtersState = useFiltersState()

    const selectedValues = filtersState.inStock.values

    const inStockSelected = selectedValues.includes('true')

    const onCheckedChange = () => {
        const selectedValues = filtersState.inStock.values

        const e = {
            selectedValues: selectedValues,
            lastToggled: 'true',
            isValid: true
        }

        handleFilterChange(e, 'inStock')
    }


    return (
        <Switch label={'In Stock Only'} onCheckedChange={onCheckedChange} checked={inStockSelected} labelClassName={styles.label} switchClassName={styles.switch} id={'instock-switch'} />
    )
}

export default InStockSwitch