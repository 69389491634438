import CATEGORIES from '@/temp-configs/category-constants'

const bedFramesBasesSetups = {
    foundationBedFrame: 'standard bed frame + foundation',
    foundationMetalFrame: 'foundation',
    platformBedFrame: 'platform bed frame',
    adjustableBase: 'adjustable base'
}

const getGalleryConfig = (configKey) => {
    const {
        foundationBedFrame,
        foundationMetalFrame,
        platformBedFrame,
        adjustableBase
    } = bedFramesBasesSetups

    const bedFramesAndBasesSharedConfig = {
        [foundationBedFrame]: {
            thumbnails: [
                {
                    name: 'amalfi-front-graphite',
                    folder: 'products/amalfi/front/graphite',
                    alt: 'amalfi front graphite'
                },
                {
                    name: 'marbella-front-no-panels-taupe',
                    folder: 'products/marbella/front/no-panels/taupe',
                    alt: 'marbella front no panels taupe'
                },
                {
                    name: 'foundation-front',
                    folder: 'products/foundation/front',
                    alt: 'foundation front'
                }
            ]
        },
        [foundationMetalFrame]: {
            thumbnails: [
                {
                    name: 'foundation-side',
                    folder: 'products/foundation/side',
                    alt: 'foundation side'
                },
                {
                    name: 'foundation-angle-2',
                    folder: 'products/foundation/angle/2',
                    alt: 'foundation angle'
                },
                {
                    name: 'foundation-silo',
                    folder: 'products/foundation/silo',
                    alt: 'foundation silo'
                }
            ]
        },
        [platformBedFrame]: {
            thumbnails: [
                {
                    name: 'siena-leather-front-espresso',
                    folder: 'products/siena-leather/front/espresso',
                    alt: 'siena leather front espresso'
                },
                {
                    name: 'sydney-front-linen',
                    folder: 'products/sydney/front/linen',
                    alt: 'sydney front linen'
                },
                {
                    name: 'siena-leather-silo-slate',
                    folder: 'products/siena-leather/silo/slate',
                    alt: 'siena leather silo slate'
                }
            ]
        },
        [adjustableBase]: {
            thumbnails: [
                {
                    name: 'adjustable-base-plus-silo-split-cal-king',
                    folder: 'products/adjustable-base-plus/silo/split-cal-king',
                    alt: 'adjustable base plus silo split cal king'
                },
                {
                    name: 'adjustable-base-silo-king',
                    folder: 'products/adjustable-base/silo/king',
                    alt: 'adjustable base silo king'
                },
                {
                    name: 'adjustable-base-silo-mattress',
                    folder: 'products/adjustable-base/silo-mattress',
                    alt: 'adjustable base silo mattress'
                }
            ]
        }
    }

    const configs = {
        [CATEGORIES.FURNITURE]: {
            ...bedFramesAndBasesSharedConfig
        }
    }

    return configs[configKey]
}

export default getGalleryConfig
