import React, { useReducer, useContext, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducers'
import * as types from './types'
import { affirmRefresh } from '@/utils/affirm'
import { joinQueryParams } from '@/utils/url'

const DataContext = React.createContext()
const StateContext = React.createContext()

const FiltersProvider = ({ contextData, children }) => {
    const {
        category,
        filtersData,
        sortData,
        initialSelectedValues,
        filterOptions,
        sortOptions,
        defaultSort,
        metaData
    } = contextData

    const staticData = useMemo(() => ({
        category,
        filterOptions,
        sortOptions,
        defaultSort,
        metaData
    }), [
        category,
        filterOptions,
        sortOptions,
        defaultSort,
        metaData
    ])

    const filterKeys = Object.keys(filtersData)

    const initialState = {
        filtersLoading: false,
        ...filtersData,
        ...sortData,
        selectedValues: initialSelectedValues,
        // tempFilters tracks the checked filters in mobile modal before they confirmed and applied
        tempFilters: {}
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const extractFilters = useCallback((currentState = state) => {
        // we can use the keys of filter data to extract only the filters without manually specifying them one by one everytime.
        const extractedFilters = {}
        filterKeys.forEach(filter => {
            extractedFilters[filter] = currentState[filter]
        })

        return extractedFilters
    }, [filterKeys, state])

    const pushBrowserHistory = useCallback((currentState) => {
        const parameters = new URLSearchParams()

        // Check each possible filter array and add it if it exists to the params
        filterKeys.forEach(filter => {
            joinQueryParams(parameters, filter, currentState[filter].values)
        })

        sortOptions.forEach(sort => {
            if (sort.value !== defaultSort && currentState.sortByLabel === sort.label) {
                parameters.set('sorting', sort.value)
            }
        })

        const protocol =  window.location.protocol
        const host = window.location.hostname
        const path = window.location.pathname

        const filters = extractFilters(currentState)

        const url = `${protocol}//${host}${path}${parameters.toString().length > 0 ? '?' : ''}${parameters}`

        history.pushState(
            {
                ...filters,
                sortByLabel: currentState.sortByLabel,
                sortBy: currentState.sortBy
            },
            '',
            url
        )
    }, [defaultSort, extractFilters, filterKeys, sortOptions])

    /**
     * Order of updatedFilters and updatedValues is important!
     */
    const updateFilters = useCallback((
        updatedFilters,
        updatedValues,
        updateBrowserHistory
    ) => {
        dispatch({ type: types.SET_LOADING, value: true })
        const updatedFilterValues = {}
        const updatedSort = {}

        // populate changes to filter and sort state
        updatedFilters.forEach((filter, i) => {
            const updatedValue = updatedValues[i]
            if (filter === 'sorting') {
                updatedSort.sortByLabel = updatedValue
                sortOptions.forEach(sort => {
                    if (sort.label === updatedValue) {
                        updatedSort.sortBy = {
                            term: sort.term,
                            order: sort.order
                        }
                    }
                })
            } else {
                updatedFilterValues[filter] = {
                    values: updatedValue
                }
            }
        })
        dispatch({
            type: types.UPDATE_FILTERS,
            updatedFilterValues,
            updatedSort,
            // if browser history should be updated, pass callback
            updateBrowserHistoryCallback: updateBrowserHistory ? pushBrowserHistory : null,
            filterKeys,
            filtersLoading: false
        })

        affirmRefresh(100)
    }, [pushBrowserHistory, sortOptions, filterKeys])

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search)

        const protocol = window.location.protocol
        const host = window.location.hostname
        const path = window.location.pathname

        const filters = extractFilters()

        // modify current browser history entry on initial load
        history.replaceState(
            {
                ...filters,
                sortByLabel: state.sortByLabel,
                sortBy: state.sortBy
            },
            '',
            `${protocol}//${host}${path}${parameters.toString().length > 0 ? '?' : ''}${parameters}`
        )

        // when user navigates through session history, update filters
        window.onpopstate = e => {
            updateFilters(
                [...filterKeys, 'sorting'],
                [
                    ...filterKeys.map(filter => e.state[filter].values),
                    e.state.sortByLabel
                ],
                false
            )
        }

        // cleanup event listener when before unmounting the component
        return () => {
            window.onpopstate = () => {}
        }
    }, [extractFilters, filterKeys, state.sortBy, state.sortByLabel, updateFilters])

    // Update mobile modal checkboxes before they are confirmed and applied
    const updateTempFilters = (newFilters) => {
        dispatch({ type: types.UPDATE_TEMP_FILTERS, value: newFilters })
    }

    // Apply the temporary mobile modal filters, then clear the temporary filters
    const updateModalFilters = () => {
        const tempFilters = state.tempFilters
        const filterKeys = Object.keys(tempFilters)
        updateFilters(
            [...filterKeys],
            [
                ...filterKeys.map(filterKey => {
                    return tempFilters[filterKey]
                })
            ],
            true
        )
        clearTempFilters()
    }

    const clearTempFilters = () => {
        dispatch({ type: types.UPDATE_TEMP_FILTERS, value: { } })
    }

    const clearFilters = () => {
        updateFilters([...filterKeys], [...filterKeys.map(() => [])], true)
    }

    const filterValues = {}
    filterKeys.forEach(key => {
        filterValues[key] = state[key]
    })

    const providedItems = {
        ...state,
        filterValues,
        updateFilters,
        clearFilters,
        updateTempFilters,
        updateModalFilters,
        dispatch
    }

    return (
        <DataContext.Provider value={staticData}>
            <StateContext.Provider value={providedItems}>
                {children}
            </StateContext.Provider>
        </DataContext.Provider>
    )
}

function useFiltersData () {
    const data = useContext(DataContext)
    if (typeof data === 'undefined') {
        throw new Error('useFiltersData must be used within FiltersProvider')
    }

    return data
}

function useFiltersState () {
    const data = useContext(StateContext)
    if (typeof data === 'undefined') {
        throw new Error('useFiltersState must be used within FiltersProvider')
    }

    return data
}

// add display name for dev tool readability
FiltersProvider.displayName = 'FiltersContext'
DataContext.displayName = 'FiltersData'
StateContext.displayName = 'FiltersState'

FiltersProvider.propTypes = {
    contextData: PropTypes.shape({
        filtersData: PropTypes.shape({
            label: PropTypes.string,
            property: PropTypes.string,
            labels: PropTypes.object,
            values: PropTypes.arrayOf(PropTypes.string)
        }).isRequired,
        sortData: PropTypes.shape({
            sortByLabel: PropTypes.string.isRequired,
            sortBy: PropTypes.shape({
                term: PropTypes.string.isRequired,
                order: PropTypes.string
            })
        }).isRequired,
        filterOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            property: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string)
        })).isRequired,
        sortOptions: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            term: PropTypes.string,
            order: PropTypes.string
        })).isRequired,
        defaultSort: PropTypes.string.isRequired,
        metaData: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            pageheader: PropTypes.string
        })).isRequired
    }),
    children: PropTypes.any
}

export { FiltersProvider, useFiltersData, useFiltersState }
