import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as ReactIs from 'react-is'
import AccordionItem from '@/components/AccordionItem/AccordionItem'

import styles from './RightSetup.module.scss'

function modulo (number, mod) {
    return ((number % mod) + mod) % mod
}

const BidirectionalTab = ({
    onSelectionChange,
    selectedAria,
    ariaControls,
    onKeyDown,
    children,
    id,
    className,
    focusedAria,
    subscribeRef,
    selectedIconColor,
    dataSelector
}) => {
    const refCallBack = useMemo(() => subscribeRef(ariaControls), [subscribeRef, ariaControls])
    const classes = selectedAria === ariaControls
        ? `${className} u-bgColor--${selectedIconColor} ${styles.bidirectionalTab}`
        : `${className} ${styles.bidirectionalTab}`

    return (
        <button
            onClick={onSelectionChange ? () => onSelectionChange(ariaControls) : undefined}
            ref={refCallBack}
            role="tab"
            tabIndex={focusedAria === ariaControls ? 0 : -1}
            aria-selected={selectedAria === ariaControls}
            aria-controls={ariaControls}
            id={id}
            onKeyDown={focusedAria === ariaControls ? onKeyDown : undefined}
            className={classes}
            data-selector={dataSelector}
        >
            {children}
        </button>
    )
}

BidirectionalTab.propTypes = {
    onSelectionChange: PropTypes.func,
    selectedAria: PropTypes.string,
    ariaControls: PropTypes.string.isRequired,
    onKeyDown: PropTypes.func,
    children: PropTypes.node,
    id: PropTypes.string,
    className: PropTypes.string,
    focusedAria: PropTypes.string,
    subscribeRef: PropTypes.func,
    selectedIconColor: PropTypes.string,
    dataSelector: PropTypes.string
}

function isTabComponent (child) {
    if (ReactIs.isFragment(child) || !ReactIs.isElement(child)) {
        return false
    }
    return child.props.ariaControls !== undefined
}

const BidirectionalTabs = ({
    children: childrenProp,
    onChange,
    selectedAria,
    className,
    sliderBar = false,
    sliderBarClassName = '',
    dataSelector
}) => {
    const [focusedAria, setFocusedAria] = useState(selectedAria)
    const [sliderRef, setSliderRef] = useState(null)
    const [tabRefs, setTabRefs] = useState({})

    const addNewTab = useCallback((ariaLabel) => (newTab) => {
        setTabRefs((previous) => {
            if (previous[ariaLabel] !== undefined) return previous
            else return { ...previous, [ariaLabel]: newTab }
        })
    }, [])

    const updateSlider = useCallback((selectedAria) => {
        sliderRef.style.left = tabRefs[selectedAria].offsetLeft + 'px'
        sliderRef.style.width = tabRefs[selectedAria].offsetWidth + 'px'
    }, [sliderRef, tabRefs])

    useEffect(() => {
        if (Object.keys(tabRefs).length > 0 && sliderRef !== null && sliderBar) {
            updateSlider(selectedAria)
        }
    }, [tabRefs, selectedAria, sliderRef, sliderBar, updateSlider])

    useEffect(() => {
        setFocusedAria(selectedAria)
    }, [selectedAria])

    const childrenPropArray = useMemo(() => React.Children.toArray(childrenProp), [childrenProp])
    const onKeyDown = (event) => {
        const code = event.key
        const keycodeMap = {
            ArrowRight: 1,
            ArrowDown: 1,
            ArrowUp: -1,
            ArrowLeft: -1
        }
        if (keycodeMap[code] !== undefined) event.preventDefault()
        const offset = (keycodeMap[code]) || 0
        const keys = Object.keys(tabRefs)
        const currentFocusedAriaIndex = keys.indexOf(focusedAria)
        const newFocusedIndex = modulo(currentFocusedAriaIndex + offset, keys.length)
        const newFocusedAria = keys[newFocusedIndex]
        setFocusedAria(newFocusedAria)
        tabRefs[newFocusedAria].focus()
    }

    const wrappedOnSelectionChange = (ariaLabel) => {
        setFocusedAria(ariaLabel)
        sliderBar && updateSlider(ariaLabel)
        if (onChange) onChange(ariaLabel)
    }

    const children = childrenPropArray.map((child) => {
        if (!ReactIs.isElement(child)) return child
        if (!isTabComponent(child)) return child
        const updatedChild = React.cloneElement(child, {
            onSelectionChange: wrappedOnSelectionChange,
            selectedAria,
            focusedAria,
            onKeyDown,
            subscribeRef: addNewTab
        })
        return updatedChild
    })

    return (
        <div role='tablist' className={className} data-selector={dataSelector}>
            {children}
            {sliderBar &&
                <div className={`${styles.sliderBar} ${sliderBarClassName}`} ref={ref => setSliderRef(ref)} aria-hidden='true'></div>
            }
        </div>
    )
}

BidirectionalTabs.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    selectedAria: PropTypes.string,
    children: PropTypes.node,
    sliderBar: PropTypes.bool,
    sliderBarClassName: PropTypes.string,
    dataSelector: PropTypes.string
}

const AccordionTabCombo = ({
    label = 'accordion',
    sections,
    accordionItemProps,
    tabListProps,
    accordionTitleClassName,
    accordionTitleOpenClassName,
    panelId,
    onChangeTab,
    initialOpenAccordianIndices = [0]
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [openAccordianIndices, setOpenAccordianIndices] = useState(initialOpenAccordianIndices)

    const handleTabClick = (ariaControl) => {
        // ariaControl argument is coming from the BidirectionalTab.jsx component and is equal to selectedAria
        setActiveTabIndex(ariaControl.substring(ariaControl.length - 1))
        onChangeTab(ariaControl.substring(ariaControl.length - 1))
    }

    const handleAccordionClick = (sectionIndex, setOpen) => {
        const sectionIsOpen = openAccordianIndices.includes(sectionIndex)

        if (setOpen && !sectionIsOpen) {
            setOpenAccordianIndices([...openAccordianIndices, sectionIndex])
        } else if (!setOpen && sectionIsOpen) {
            setOpenAccordianIndices(openAccordianIndices.filter(idx => idx !== sectionIndex))
        }
    }

    const activeSectionContent = sections[activeTabIndex].content

    const accordionItems = sections.map((section, sectionIdx) => {
        const sectionIsOpen = openAccordianIndices.includes(sectionIdx)
        const titleClasses = classNames(accordionTitleClassName, {
            [accordionTitleOpenClassName]: sectionIsOpen
        })

        return (
            <AccordionItem
                title={section.title}
                titleClassName={titleClasses}
                isOpen={sectionIsOpen}
                isOpenOnRender={sectionIsOpen}
                setOpen={handleAccordionClick}
                preventInitialJump
                accordionIndex={sectionIdx}
                key={sectionIdx}
                panelId={`${panelId}-${sectionIdx}`}
                {...accordionItemProps}
            >
                { section.content }
            </AccordionItem>
        )
    })

    return (
        <>
            <div className="row u-hidden--lg-up">
                <div className="col col--xs-12">
                    {accordionItems}
                </div>
            </div>
            <div className="row u-hidden--lg-down">
                <div className="col col--xs-12">
                    <BidirectionalTabs
                        onChange={handleTabClick}
                        selectedAria={`rightSetupPanel-${activeTabIndex}`}
                        sliderBar={true}
                        sliderBarClassName={tabListProps.sliderClassName}
                        className={tabListProps.className}
                        dataSelector={`${label}-tabs`}
                    >
                        {sections.map((section, index) => (
                            <BidirectionalTab
                                ariaControls={`rightSetupPanel-${index}`}
                                id={`rightSetupTab-${index}`}
                                className={tabListProps.tabClassName}
                                key={index}
                                dataSelector={`${label}-tab`}
                            >
                                {section.title}
                            </BidirectionalTab>
                        ))}
                    </BidirectionalTabs>
                </div>
            </div>
            <div
                role="tabpanel"
                id={`rightSetupPanel-${activeTabIndex}`}
                aria-labelledby={`rightSetupTab-${activeTabIndex}`}
                className="u-hidden--lg-down"
                tabIndex={0}
            >
                {activeSectionContent}
            </div>
        </>
    )
}

AccordionTabCombo.propTypes = {
    label: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.node
    })),
    accordionItemProps: PropTypes.object,
    tabListProps: PropTypes.object,
    accordionTitleClassName: PropTypes.string,
    accordionTitleOpenClassName: PropTypes.string,
    panelId: PropTypes.string,
    onChangeTab: PropTypes.func,
    initialOpenAccordianIndices: PropTypes.arrayOf(PropTypes.number)
}

export default AccordionTabCombo
