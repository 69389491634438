import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@saatva-bits/pattern-library.components.button'

import styles from './ShopTheRoom.module.scss'

const ShopTheRoom = ({ sectionClassName }) => {

    const ctaProps = [
        {
            text: 'Shop all furniture',
            href: '/furniture',
            kind: 'secondary'
        },
        {
            text: 'Shop all bedding',
            href: '/bedding',
            kind: 'secondary'
        }
    ]
    const containerClasses = classNames(styles.section, sectionClassName)

    return (
        <section className={containerClasses}>
            <div className={styles.sectionContent}>
                <div className={`col col--xs-12 ${styles.header}`}>
                    <h2 className={`t-heading1 t-color--white ${styles.title}`}>
                        Shop the bedroom
                    </h2>
                </div>
                <div className="col col--xs-12 u-flexAlign--center">
                    <h3 className={`t-heading3 t-color--white col--sm-9 col--md-8 col--lg-7 col--xl-6 col--2xl-6 ${styles.description}`}>
                        We're way more than just mattresses. Our handcrafted bedding & furniture instantly transform your sleep space into a luxurious retreat.
                    </h3>
                </div>
                <div className={styles.ctasContainer}>
                    {ctaProps.map((cta, index) => (
                        <Button
                            key={index}
                            kind={cta.kind}
                            className={`u-marginTop--sm u-marginBottom--xs ${styles.button}`}
                            href={cta.href}
                        >
                            {cta.text}
                        </Button>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ShopTheRoom

ShopTheRoom.propTypes = {
    sectionClassName: PropTypes.string
}
