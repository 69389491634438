import React from 'react'
import CATEGORIES from '@/temp-configs/category-constants'

import Gallery from '@/components/Gallery/Gallery'
import InfoTooltip from '@/components/InfoTooltip/InfoTooltip'

import styles from './RightSetup.module.scss'

const link = (link, text) => (
    <a href={link} className="t-link" data-selector="rightsetup-link">
        {text}
    </a>
)

const furnitureSections = [
    {
        title: 'platform bed frame',
        greatForBullets: [
            'A sleek, low-profile look',
            'Those who prefer not to use a foundation or box spring'
        ],
        compatibilitySection: {
            title: 'Compatible with:',
            content: (
                <>
                    <p className='u-marginBottom--none'>Most mattresses</p>
                    <InfoTooltip
                        className={styles.tooltip}
                        buttonClassName={styles.tooltipButton}
                        contentClassName={styles.tooltipContent}
                    >
                        <p>NOTE: Must add a solid surface over the slats such as plywood, masonite board, or bunkie board, when used with the dual-sided <span className='t-base'>Zenhaven Natural Latex</span> Mattress.</p>
                    </InfoTooltip>
                </>
            )
        },
        showShopButton: true,
        shopButton: [{
            text: 'Shop platform bed frames',
            href: '/furniture?types=platform+bed+frames',
            className: 'col col--xs-12 col--md-5 col--lg-8 col--xl-6'
        }],
        galleryComponent: Gallery,
        galleryComponentProps: (customProps) => ({
            label: 'rightsetup',
            configKey: customProps.categoryName,
            activeItem: customProps.sectionTitle,
            direction: customProps.isTablet ? 'right' : 'bottom',
            ...customProps
        })
    },
    {
        title: 'standard bed frame + foundation',
        foundationVsBoxSpring: {
            title: 'Foundation vs. box spring: What’s the difference?',
            description: <span>A mattress foundation is a <span className="t-base">new & improved</span> version of the old-fashioned box spring. Foundations use more stable wood support slats, while box springs use bouncier metal coils for support.</span>
        },
        greatForBullets: [
            'Making your bed the focal point of your bedroom',
            'Giving added height to your mattress',
            'Mattresses that require solid surface support (no slats), like our Zenhaven Latex Mattress & Saatva HD'
        ],
        compatibilitySection: {
            title: 'Compatible with:',
            content: (
                <p className='u-marginBottom--none'>{link('/mattresses', 'All mattresses')} (Our Saatva HD requires the HD foundation for optimal support)</p>
            )
        },
        showShopButton: true,
        shopButton: [
            {
                text: 'Shop mattress foundation',
                href: '/furniture/foundation',
                className: 'col col--xs-12 col--md-5 col--lg-8 col--xl-6'
            },
            {
                text: 'Shop standard bed frames',
                href: '/furniture?types=standard+bed+frames',
                className: 'col col--xs-12 col--md-5 col--lg-8 col--xl-6'
            }
        ],
        galleryComponent: Gallery,
        galleryComponentProps: (customProps) => ({
            label: 'rightsetup',
            configKey: customProps.categoryName,
            activeItem: customProps.sectionTitle,
            direction: customProps.isTablet ? 'right' : 'bottom',
            ...customProps
        })
    },
    {
        title: 'foundation',
        foundationVsBoxSpring: {
            title: 'Foundation vs. box spring: What’s the difference?',
            description: <span>A mattress foundation is a <span className="t-base">new & improved</span> version of the old-fashioned box spring. Foundations use more stable wood support slats, while box springs use bouncier metal coils for support.</span>
        },
        greatForBullets: [
            'Minimalists who want the simplest and least expensive bed setup',
            'Those who move often and don’t want to disassemble a large bed frame each time'
        ],
        compatibilitySection: {
            title: 'Compatible with:',
            content: (
                <>
                    <p className='u-marginBottom--none'>{link('/mattresses', 'All mattresses')} (Our Saatva HD requires the HD foundation for optimal support)</p>
                    <p className={`${styles.disclaimer} u-marginBottom--none`}>Optional metal frame sold separately.</p>
                </>
            )
        },
        showShopButton: true,
        shopButton: [{
            text: 'Shop mattress foundation',
            href: '/furniture/foundation',
            className: 'col col--xs-12 col--md-7 col--lg-12 col--xl-8'
        }],
        galleryComponent: Gallery,
        galleryComponentProps: (customProps) => ({
            label: 'rightsetup',
            configKey: customProps.categoryName,
            activeItem: customProps.sectionTitle,
            direction: customProps.isTablet ? 'right' : 'bottom',
            ...customProps
        })
    },
    {
        title: 'adjustable base',
        greatForBullets: [
            'Helping to relieve back & joint pain',
            'Those who enjoy reading, watching TV, or working from bed',
            'Having the freedom to raise the head and the feet of the mattress independently.*'
        ],
        greatForDisclaimer: '*Feature only available on the Saatva Adjustable Base Plus Split King or Split Cal King sizes*',
        compatibilitySection: {
            title: 'Compatible with:',
            content: (
                <ul className={styles.list}>
                    <li>All Saatva mattresses except Saatva Classic 14.5” height & Saatva HD</li>
                    <li>All Saatva bed frames except Sydney Platform Bed Frame and all storage bed frames</li>
                </ul>
            )
        },
        showShopButton: true,
        shopButton: [{
            text: 'Shop adjustable bases',
            href: '/furniture?types=adjustable+bases',
            className: 'col col--xs-12 col--md-5 col--lg-8 col--xl-6'
        }],
        galleryComponent: Gallery,
        galleryComponentProps: (customProps) => ({
            label: 'rightsetup',
            configKey: customProps.categoryName,
            activeItem: customProps.sectionTitle,
            direction: customProps.isTablet ? 'right' : 'bottom',
            ...customProps
        })
    }
]

const rightSetupConfig = {
    [CATEGORIES.FURNITURE]: {
        title: 'Find the right bed setup for you',
        subtitles: [
            'Comfort starts from the ground up. The right bed base not only upgrades your sleep comfort, it can increase the lifespan of your mattress.',
            'Let\'s break down all of the options:'
        ],
        sections: furnitureSections,
        tabClassName: `${styles.tabName} t-heading6 t-light`,
        tabSliderClassName: styles.tabSlider
    }
}

export default rightSetupConfig
