import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import getGalleryConfig from './GalleryConfig'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'

import styles from './Gallery.module.scss'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const GalleryItem = ({
    name,
    folder,
    alt,
    className,
    currentItem,
    selectedItem,
    setSelectedItem,
    label
}) => {
    const { isMobile, isTablet } = useBreakpoints('mobile')
    const hasCurrentItem = currentItem !== undefined
    const isSelected = currentItem === selectedItem || !hasCurrentItem

    const pictureClasses = classNames(
        styles.image,
        { [styles['image--selected']]: isSelected }
    )

    const pictureAspectRadio = hasCurrentItem
        ? (isTablet ? '16-9' : '3-2')
        : (isMobile ? '3-2' : '16-9')

    return (
        <button
            data-selector={`${label}-item`}
            className={className}
            {...hasCurrentItem && { onClick: () => setSelectedItem(currentItem) }}
        >
            <Picture
                className={pictureClasses}
                imgixDomain={IMGIX_URL}
                name={`${name}-${pictureAspectRadio}.jpg`}
                folder={folder}
                alt={alt}
                lazyLoad={true}
                widths={{
                    mobile: 320,
                    tablet: 520,
                    desktop: 575
                }}
                prefixOverride={{
                    mobile: 'none',
                    tablet: 'none',
                    desktop: 'none'
                }}
                data-selector={`${label}-image${isSelected ? '-selected' : ''}`}
            />
        </button>
    )
}

GalleryItem.propTypes = {
    name: PropTypes.string,
    folder: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    currentItem: PropTypes.number,
    selectedItem: PropTypes.number,
    setSelectedItem: PropTypes.func,
    label: PropTypes.string
}

const Gallery = ({
    label,
    configKey,
    activeItem,
    direction = 'bottom'
}) => {
    const config = getGalleryConfig(configKey)
    const [selectedItem, setSelectedItem] = useState(0)
    const { thumbnails } = config[activeItem]

    const galleryClassName = classNames(
        styles.gallery,
        'u-hidden--md-down',
        { [styles[`gallery--${direction}`]]: direction }
    )
    const galleryCarouselClassName = classNames(styles.galleryCarousel, 'u-hidden--md-up')

    return (
        <>
            <div
                data-selector={`${label}-gallery-carousel`}
                className={galleryCarouselClassName}
            >
                <SimpleSlider
                    peekable
                    infinite
                    overflow="visible"
                    showButtons={false}
                    sliderLabel="gallery"
                    childClass={styles.itemWrapper}
                >
                    {thumbnails.map((itemProps, index) =>
                        <GalleryItem
                            key={index}
                            label={label}
                            {...itemProps}
                            className={styles.item}
                        />
                    )}
                </SimpleSlider>
            </div>
            <div
                data-selector={`${label}-gallery`}
                className={galleryClassName}
            >
                <GalleryItem
                    label={label}
                    className={`${styles.item} ${styles['item--selected']}`}
                    {...thumbnails[selectedItem]}
                />
                {thumbnails.map((itemProps, index) =>
                    <GalleryItem
                        key={index}
                        label={label}
                        {...itemProps}
                        currentItem={index}
                        className={styles.item}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                    />
                )}
            </div>
        </>
    )
}

Gallery.propTypes = {
    label: PropTypes.string.isRequired,
    configKey: PropTypes.string.isRequired,
    activeItem: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['right', 'bottom'])
}

export default Gallery
