import * as types from './types'
import { getSelectedValues } from '@/utils/filters'

const reducer = (state, action) => {
    switch (action.type) {
        case types.UPDATE_FILTERS: {
            const {
                updatedFilterValues,
                updatedSort,
                updateBrowserHistoryCallback,
                filterKeys
            } = action

            // all sort related fields are included in updatedSort
            let newState = { ...state, ...updatedSort }

            // assign filter values directly to prevent overwritting other filter fields
            for (const filter in updatedFilterValues) {
                newState[filter].values = [...updatedFilterValues[filter].values]
            }

            // update the selected values
            const filterValues = {}
            filterKeys.forEach(key => {
                filterValues[key] = state[key]
            })

            newState.filterValues = filterValues
            newState.selectedValues = getSelectedValues(filterValues)

            // using callback pattern due to issues with alternative solutions
            // watching for filter & sort changes with useEffect triggers on session navigation
            // adding shouldUpdateBrowserHistory bool to state fails to run due to multiple actions
            // being dispatched before state is updated
            // a promise based pattern might be possible
            if (updateBrowserHistoryCallback) {
                updateBrowserHistoryCallback(newState)
            }

            return newState
        }

        case types.UPDATE_TEMP_FILTERS: {
            return { ...state, tempFilters: { ...action.value } }
        }

        case types.SET_LOADING: {
            return { ...state, filtersLoading: action.value }
        }

        default: return state
    }
}

export default reducer
