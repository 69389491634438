import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFiltersState } from '@/contexts/filters'

import { Button } from '@saatva-bits/pattern-library.components.button'
import { Icon } from '@saatva-bits/pattern-library.components.icon'
import { Modal } from '@saatva-bits/pattern-library.components.modal'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import FiltersGroup from '../FilterGroup/FiltersGroup'

import styles from './FilterModal.module.scss'

const FilterModalFooter = ({ onApply, onClose }) => {
    return (
        <div className="u-flexDisplay u-flexDirection--column">
            <Button
                kind="primary"
                onClick={() => {
                    onApply()
                    onClose()
                }}
            >
                Apply
            </Button>
            <Button kind="secondary" className="u-marginTop--sm" onClick={onClose}>
                Cancel
            </Button>
        </div>
    )
}

const FilterModal = ({ filters = {}, products }) => {
    const filterState = useFiltersState()
    const { isDesktop } = useBreakpoints('tablet')
    const {
        tempFilters,
        updateTempFilters,
        updateModalFilters
    } = filterState
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        // Initialize the temp filters with what is already in context on modal opening
        const initFilters = {}
        filters.forEach(filter => {
            const filterKey = filter.property
            initFilters[filterKey] = [...filterState[filterKey].values]
        })

        updateTempFilters(initFilters)
    }, [isModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isDesktop) {
            setIsModalVisible(false)
        }
    }, [isDesktop])

    const handleOnClose = () => {
        setIsModalVisible(false)
    }

    const handleFilterChange = (event, filterKey) => {
        const newFilters = { ...tempFilters }
        const actionType = event.selectedValues.includes(event.lastToggled)
            ? 'remove'
            : 'add'

        const newFilterVals = tempFilters[filterKey] || []

        if (actionType === 'add') {
            newFilterVals.push(event.lastToggled)
        } else if (actionType === 'remove') {
            newFilterVals.splice(newFilterVals.indexOf(event.lastToggled), 1)
        }

        newFilters[filterKey] = newFilterVals
        updateTempFilters(newFilters)
    }

    return (
        <>
            <Button className={styles.modalBtn} onClick={() => setIsModalVisible(true)}>
                FILTER
                <Icon
                    className={styles.chevronIcon}
                    name="expand"
                    alt="Expand filter icon"
                    description="Expand filter icon"
                    titleId="expandIcon"
                />
            </Button>
            <Modal
                modalLabel="filter modal"
                type="popup"
                className={styles.modalContainer}
                contentWrapperClassName={styles.modalContentWrapper}
                closeButtonClass={styles.closeBtn}
                isModalVisible={isModalVisible}
                onModalClose={handleOnClose}
                modalFooter={
                    <FilterModalFooter
                        onApply={updateModalFilters}
                        onClose={handleOnClose}
                    />
                }
            >
                <h2 className={styles.modalTitle}>FILTER BY</h2>
                <FiltersGroup
                    isModal
                    filters={filters}
                    products={products}
                    handleFilterChange={handleFilterChange}
                />
            </Modal>
        </>
    )
}

FilterModal.propTypes = {
    filters: PropTypes.arrayOf(PropTypes.object).isRequired,
    products: PropTypes.array.isRequired
}

export default FilterModal
