import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import debounce from 'lodash/debounce'

import { Icon } from '@saatva-bits/pattern-library.components.icon'

import styles from './AccordionItem.module.scss'

const AccordionItem = ({
    accordionIndex,
    children,
    className,
    contentClassName,
    iconClassName,
    itemBgModifier,
    itemId,
    isOpen,
    isOpenOnRender = false,
    onClick = () => {},
    onTitleClick = () => {},
    setOpen,
    title,
    titleClassName,
    titleElementOverride,
    titleTag = 'h3',
    useChevron = false,
    panelId,
    checkResize = false
}) => {
    const ref = useRef(null)
    const contentRef = useRef(null)
    const contentPaddingRef = useRef(null)
    const [isResizing, setIsResizing] = useState(false)
      
    const handleWindowSizeChange = useCallback(() => {
        setIsResizing(true)
        setTimeout(()=>setIsResizing(false), 200)
    }, [])

    useEffect(() => {
        if (checkResize) window.addEventListener('resize', debounce(handleWindowSizeChange, 250))

        return () => {
            if (checkResize) window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [handleWindowSizeChange, checkResize])

    useEffect(() => {
        if (isOpen) {
            contentRef.current.style.height = `${contentPaddingRef.current.offsetHeight}px`
        } else {
            contentRef.current.style.height = '0px'
        }
    }, [isOpen, isResizing])

    const handleKeyPress = (event) => {
        const isKeyPressTarget = event.target === event.currentTarget
        const isValidKeyPress = [13, 32].indexOf(event.which) !== -1

        if (isKeyPressTarget && isValidKeyPress) {
            handleTitleClick(event, setOpen, onTitleClick)
        }
    }

    const handleTitleClick = (event) => {
        const classes = Object.values(event.target.classList)

        // If the user clicks a tooltip in the title, don't expand/collapse
        if (!classes.some((className) => className.toLowerCase().includes('tooltip'))) {
            setOpen(accordionIndex, !isOpen, ref)
            onTitleClick(event)
        }
    }

    const iconName = useChevron ? 'arrow-down' : isOpen ? 'less' : 'more'

    const titleClasses = classNames(styles.title, titleClassName)
    const TitleTag = `${titleTag}`
    const titleNode = titleElementOverride || <TitleTag className={titleClasses}>{title}</TitleTag>

    const classes = classNames(
        {
            [styles['is-open']]: isOpen,
            [styles['using-chevron']]: useChevron,
            [styles[`item--${itemBgModifier}`]]: itemBgModifier
        },
        styles.item,
        className
    )

    const contentClasses = classNames(
        styles.content,
        contentClassName, {
            [styles['content--defaultOpen']]: isOpenOnRender || (isResizing && isOpen)
        }
    )

    const iconClassNames = classNames(styles.icon, {
        [styles['accordionItemChevron-open']]: useChevron && isOpen,
        [styles['accordionItemChevron-closed']]: useChevron && !isOpen
    }, iconClassName)

    return (
        <div
            className={classes}
            onClick={onClick}
            onKeyPress={(e) => handleKeyPress(e, setOpen, onTitleClick)}
            role="presentation"
            id={itemId || null}
            ref={ref}
        >
            <button
                className={styles.heading}
                type="button"
                aria-controls={panelId}
                aria-expanded={isOpen}
                onClick={handleTitleClick}
            >
                {titleNode}
                <Icon
                    alt={iconName}
                    className={iconClassNames}
                    description="Expand/Collapse"
                    name={iconName}
                    titleId="accordionIcon"
                />
            </button>
            <div
                id={panelId}
                className={contentClasses}
                ref={contentRef}
            >
                <div className={styles.contentPadding} ref={contentPaddingRef}>
                    {children}
                </div>
            </div>
        </div>
    )
}

AccordionItem.propTypes = {
    accordionIndex: PropTypes.number,
    itemBgModifier: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    isOpen: PropTypes.bool,
    isOpenOnRender: PropTypes.bool,
    startOpen: PropTypes.bool,
    itemId: PropTypes.string,
    onClick: PropTypes.func,
    onTitleClick: PropTypes.func,
    preventInitialJump: PropTypes.bool,
    setOpen: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleClassName: PropTypes.string,
    titleTag: PropTypes.string,
    titleElementOverride: PropTypes.element,
    useChevron: PropTypes.bool,
    panelId: PropTypes.string,
    checkResize: PropTypes.bool
}

export default AccordionItem
