import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import valuePropsConfig from './ValuePropCardsConfig'

import styles from './ValuePropCards.module.scss'

const ValuePropCard = ({
    title,
    description,
    svg,
    cardClassnames,
    contentClassnames,
    headingClassnames,
    titleClassnames,
    descriptionClassnames,
}) => {
    const cardClasses = classNames(styles.valuePropCard, cardClassnames)
    const contentClasses = classNames(styles.content, contentClassnames)
    const headingClasses = classNames(styles['content-heading'], headingClassnames)
    const titleClasses = classNames(styles['content-heading-title'], titleClassnames)
    const descriptionClasses = classNames(styles['content-description'], descriptionClassnames)

    return (
        <div className={cardClasses}>
            <div className={contentClasses}>
                <div className={headingClasses}>
                    {svg}
                    <h4 className={titleClasses}>
                        {title}
                    </h4>
                </div>
                <div className={descriptionClasses}>
                    <p>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}

ValuePropCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    svg: PropTypes.node.isRequired,
    cardClassnames: PropTypes.string,
    contentClassnames: PropTypes.string,
    headingClassnames: PropTypes.string,
    titleClassnames: PropTypes.string,
    descriptionClassnames: PropTypes.string,
}

const ValuePropCards = () => {
    return (
        <section className={classNames('section', styles.valuePropCards)}>
            <div className="container">
                <div className="row">
                    {valuePropsConfig.map((valueProp, index) => (
                        <ValuePropCard
                            key={`valuePropCard-${index}`}
                            {...valueProp}
                            cardClassnames="col col--xs-12 col--sm-6 col--xl-3"
                            contentClassnames={styles.cardContent}
                            headingClassnames={styles.cardHeading}
                            titleClassnames={styles.cardTitle}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ValuePropCards
