import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isFunction from 'lodash/isFunction'
import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import rightSetupConfig from './RightSetupConfig'

import { Button } from '@saatva-bits/pattern-library.components.button'
import AccordionTabCombo from './AccordionTabCombo'

import styles from './RightSetup.module.scss'

const RightSetupContent = ({
    gallery,
    foundationVsBoxSpring,
    greatForBullets,
    greatForDisclaimer,
    compatibilityTitle,
    compatibilityContent,
    showShopButton,
    shopButtonsData,
    shopButtonOnClick,
    galleryContainerClassName = 'col col--xs-12 col--lg-7 col--xl-6'
}) => {
    return (
        <div className="row">
            <div className={galleryContainerClassName}>
                {gallery}
            </div>
            <div className={`col col--xs-12 col--lg-5 col--xl-6 u-textLeft ${styles.infoPanel}`}>
                {foundationVsBoxSpring &&
                    <>
                        <div className='t-heading4 u-marginBottom--2xs'>
                            {foundationVsBoxSpring.title}
                        </div>
                        <div className='u-marginBottom--sm'>
                            {foundationVsBoxSpring.description}
                        </div>
                    </>
                }
                {greatForBullets?.length &&
                    <>
                        <div className="t-heading4 u-marginBottom--2xs">
                            This setup is great for:
                        </div>
                        <ul className={styles.list}>
                            {greatForBullets.map((bulletHTML, bulletIdx) => (
                                <li key={bulletIdx} dangerouslySetInnerHTML={{ __html: bulletHTML }}/>
                            ))}
                        </ul>
                        {greatForDisclaimer &&
                            <div className={`${styles.disclaimer} u-paddingHorizontal--md`}>{greatForDisclaimer}</div>
                        }
                    </>
                }
                {compatibilityTitle &&
                    <div className="t-heading4 u-marginBottom--2xs u-marginTop--sm">{compatibilityTitle}</div>
                }
                {compatibilityContent}
                {showShopButton &&
                    <div className="row">
                        {shopButtonsData?.map((shopButton, index) => {
                            return (
                                <div className={shopButton.className} key={index}>
                                    <Button
                                        kind="primary"
                                        className={`u-paddingTop--sm u-marginTop--md ${styles.button}`}
                                        href={shopButton.href}
                                        onClick={shopButtonOnClick}
                                        data-selector="rightsetup-cta"
                                    >
                                        {shopButton.text}
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

RightSetupContent.propTypes = {
    gallery: PropTypes.node,
    greatForBullets: PropTypes.array,
    foundationVsBoxSpring: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
    }),
    greatForDisclaimer: PropTypes.string,
    compatibilityTitle: PropTypes.string,
    compatibilityContent: PropTypes.node,
    showShopButton: PropTypes.bool,
    shopButtonOnClick: PropTypes.func
}

const RightSetup = ({ categoryName, className }) => {
    const { isTablet } = useBreakpoints('tablet')
    // eslint-disable-next-line no-unused-vars
    const [activeTab, setActiveTab] = useState('0')
    const {
        title,
        subtitles,
        sections: categorySections = [],
        tabClassName,
        tabSliderClassName,
        galleryContainerClassName
    } = rightSetupConfig[categoryName]

    const activeTabCallback = (tabIndex) => {
        setActiveTab(tabIndex)
    }

    const sections = categorySections?.map((section) => {
        const GalleryComponent = section.galleryComponent
        const galleryProps = isFunction(section.galleryComponentProps)
            ? section.galleryComponentProps({ categoryName, sectionTitle: section.title, isTablet })
            : section.galleryComponentProps

        const SectionContent = <RightSetupContent
            galleryContainerClassName={galleryContainerClassName}
            gallery={<GalleryComponent {...galleryProps} />}
            greatForBullets={section.greatForBullets}
            foundationVsBoxSpring={section.foundationVsBoxSpring}
            greatForDisclaimer={section.greatForDisclaimer}
            compatibilityTitle={section.compatibilitySection?.title}
            compatibilityContent={section.compatibilitySection?.content}
            showShopButton={section.showShopButton}
            shopButtonsData={section.shopButton}
            shopButtonOnClick={section.shopButton?.shopButtonOnClick}
        />

        return ({
            title: section.title,
            content: SectionContent
        })
    })

    const classes = classNames('section', styles.rightSetup, className)

    return (
        <section className={classes} data-selector="rightsetup">
            <div className="container">
                <h2 className={`${styles.title} u-marginBottom--xs`}>
                    {title}
                </h2>
                {subtitles?.map((subtitle, index) => {
                    const isLastSubtitle = index === (subtitles.length - 1)
                    const subtitleClasses = classNames('t-bodyLg', styles.subtitle, {
                        'u-marginBottom--xs': !isLastSubtitle,
                        [styles['subtitle--bottomMargin']]: isLastSubtitle
                    })
                    return <p key={index} className={subtitleClasses}>{subtitle}</p>
                })}
                <AccordionTabCombo
                    label="rightsetup"
                    sections={sections}
                    accordionTitleClassName={`t-heading6 t-light t-color u-paddingVertical--md ${styles.accordionTitle}`}
                    accordionTitleOpenClassName="t-base"
                    accordionItemProps={{
                        itemBgModifier: 'noPadding',
                        className: styles.accordionItem,
                        titleTag: 'h3',
                        iconClassName: styles.accordionIcon,
                        checkResize: true
                    }}
                    tabListProps={{
                        className: `u-marginBottom--2dot5xl ${styles.tabList}`,
                        tabClassName: tabClassName,
                        sliderClassName: tabSliderClassName
                    }}
                    panelId="rightSetupAccordionPanel"
                    onChangeTab={activeTabCallback}
                />
            </div>
        </section>
    )
}

RightSetup.propTypes = {
    categoryName: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default RightSetup
